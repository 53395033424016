<template>
  <div>
    <!-- Headline -->
    <HeroCurved :heading="$t('become_seller_title')" :subheading="$t('become_seller_copy')" :button="true"/>

    <div class="main-container p-0">
      <div class="container-fluid mt-4 mb-3 mb-md-5">
        <HowItWorks/>
      </div>
      <MeetTheSeller />
      <div class="container-fluid products pt-0 pt-md-2 px-0 px-md-3">

        <!-- AT -->
        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3 at">
            <!-- video -->
            <b-col class="col-12 col-md-4 p-0 video overflow-hidden">
              <div v-if="!showYoutube" class="d-flex flex-column h-100 justify-content-center align-items-center graphic">
                <h4 class="font-weight-bold">{{ $t('why_become_seller') }}</h4>
                <i @click="showYoutube = !showYoutube" class="fa-solid fa-circle-play" aria-hidden="true"></i>
              </div>
              <iframe v-else width="100%" height="220" :src="youtubeLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </b-col>
            <!-- Copy -->
            <b-col class="col-12 col-md-8 d-flex flex-column align-items-start pl-3 pl-md-5 mt-4 m-md-0 px-4 px-md-0">
              <h3 class="font-weight-bold mb-4 w-100">{{ $t('become_seller.anytask.title')}}</h3>
              <p class="text-left mb-4">{{ $t('become_seller.anytask.copy') }}</p>
              <div class="d-flex flex-column flex-md-row font-weight-bold w-100 features">
                <div class="mr-0 mr-md-4 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                  <img :src="require('@/assets/BecomeASeller/worldwide.svg')" class="mr-2">
                  <div>{{ $t('become_seller.anytask.feature_one') }}</div>
                </div>
                <div class="mr-0 mr-md-4 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                  <img :src="require('@/assets/BecomeASeller/zero-fees.svg')" class="mr-2">
                  <div>{{ $t('become_seller.anytask.feature_two') }}</div>
                </div>
                <div class="mr-0 mr-md-0 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                  <img :src="require('@/assets/BecomeASeller/without-bank.svg')" class="mr-2">
                  <div>{{ $t('become_seller.anytask.feature_three') }}</div>
                </div>
              </div>
            </b-col>
        </div>

        <!-- ETN -->
        <div class="d-flex flex-row pt-0 pt-md-5 pb-5 etn">
          <!-- image -->
          <b-col order-md="2" class="col-12 col-md-4 image d-flex justify-content-center align-items-center mt-4 m-md-0">
            <img :src="require('@/assets/BecomeASeller/electroneum-logo-light.svg')" class="logo">
          </b-col>
          <!-- copy -->
          <b-col order-md="1" class="col-12 col-md-8 d-flex flex-column align-items-start pr-3 pr-md-5 px-4 px-md-0">
            <h3 class="font-weight-bold mb-4 mt-4 mt-md-0 w-100">{{ $t('become_seller.electroneum.title') }}</h3>
            <p class="text-left">{{ $t('become_seller.electroneum.p1') }}</p>
            <p class="text-left mb-4" v-html="$t('become_seller.electroneum.p2', { link: 'http://etneverywhere.com/' })"></p>
            <b-button v-b-modal.etn-modal class="btn px-4 w-100">{{ $t('find_out_more_etn') }}</b-button>
          </b-col>
        </div>

        <!-- modal -->
        <ElectroneumInfo/>
      </div>

      <!-- lower banner -->
      <div id="lower-banner" class="w-100 position-relative d-flex flex-column justify-content-center align-items-center">
        <img :src="require('@/assets/BecomeASeller/lower-banner-left.png')" class="position-absolute">
        <img :src="require('@/assets/BecomeASeller/lower-banner-right.png')" class="position-absolute d-none d-md-block">
        <div class="content px-3">
          <h2 class="font-weight-bold mx-4 mx-sm-0">{{ $t('become_seller_signup_footer') }}</h2>
          <div v-if="accessToken && !roleSeller">
            <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'connecting-etn' }">{{ $t('become_seller_button_sign_me_up') }}</b-button>
          </div>
          <!-- not logged in TO SIGNUP -->
          <div v-else-if="!accessToken">
            <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" v-on:click="loadSignUp()">{{ $t('become_seller_button_sign_me_up') }}</b-button>
          </div>
          <!-- logged in / is seller TO CREATE TASK-->
          <div v-else>
            <b-button class="btn-primary font-weight-bold px-5 py-2 d-block d-sm-inline-flex w-100" :to="{ name: 'createTask' }">{{ $t('become_seller_button_task') }}</b-button>
          </div>
        </div>
      </div>

    </div>
    <SignUp modal-id="sellerSignUpModal" :seller-modal="true"/>
  </div>
</template>

<script>
import ElectroneumInfo from '../components/Modal/ElectroneumInfo.vue'
import HeroCurved from '../components/Global/HeroCurved.vue'
import HowItWorks from '@/components/BecomeSeller/HowItWorks.vue'
import MeetTheSeller from '@/components/Global/MeetTheSeller'
import SignUp from '@/components/MyAccount/SignUp'
import config from '../config'
import helper from '../helper'

export default {
  name: 'home',
  props: {
    image: Image,
    title: String,
    text: String
  },
  metaInfo () {
    return {
      title: 'AnyTask - Earn doing what you love',
      description: 'Sell your digital skills on AnyTask and earn ETN',
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'AnyTask - Earn doing what you love'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://images.anytask.com/image/upload/b_rgb:ffffff,c_lpad,co_rgb:ffffff,f_jpg,h_630,w_1200/urbiknsbdw7r5mpntg5w.jpg'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Sell your digital skills on AnyTask and earn ETN'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/become-seller'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  },
  mounted () {
    helper.reorderMetas()
  },
  data () {
    return {
      tracking_switch: config.tracking_enabled === 'true',
      showYoutube: false,
      youtubeLink: 'https://www.youtube.com/embed/vmpNiY2tsi0?autoplay=1&;enablejsapi=1',
      roleSeller: localStorage.getItem('role_seller'),
      accessToken: localStorage.getItem('accessToken')
    }
  },
  components: {
    HeroCurved,
    ElectroneumInfo,
    HowItWorks,
    MeetTheSeller,
    SignUp
  },
  methods: {
    loadSignUp () {
      this.$bvModal.show('sellerSignUpModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  #content {
    background-color: white !important;
  }

  #meet-the-freelancer {
    background-image: url('~@/assets/BecomeASeller/bg-meet-the-freelancer.jpg') !important;
    @include md-up {
      background-image: url('~@/assets/BecomeASeller/bg-meet-the-freelancer-xl.jpg') !important;
      background-position: 66% center;
      background-size: cover;
    }
  }

  .main-container {
    background: white;
    .container-fluid {
      max-width: 1140px !important;
    }
    .products {
      p {
        line-height: 1.675rem;
      }
      .image, .video {
        height: 250px;
      }
      .at {
        .video {
          background: #000;
          .graphic {
            background-image: url('~@/assets/BecomeASeller/video-overlay.png');
            background-position: left center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: $brand-sky;
            color: white;
          }
          .fa-circle-play {
            font-size: 4.0rem;
            opacity: 0.7;
            transition: all .25s;
            &:hover {
              opacity: 1;
              transform: scale(1.2);
              transition: all .25s;
              cursor: pointer;
            }
          }
        }
        .features {
          font-size: 14px;
          img {
            width: 24px;
          }
        }
      }
      .etn {
        .btn {
          background: transparent;
          border-color: $brand-sky !important;
          color: $brand-sky !important;
          &:hover {
            color: white !important;
            background: $brand-sky !important;
          }
        }
        .image {
          background: url('~@/assets/BecomeASeller/etn-about.jpg');
          background-size: cover;
          .logo {
            width: 200px;
          }
        }
      }
    }
  }

  #lower-banner {
    background: $brand-navy;
    min-height: 260px;
    color: white;
    overflow: hidden;
    .content {
      z-index: 999;
    }
    img {
      height: 100%;
      &:nth-child(1) {
        left: 0;
        top: 0;
        bottom: 0;
      }
      &:nth-child(2) {
        right: -50px;
        top: 0;
        bottom: 0;
      }
    }
  }

  .hero {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url(../assets/BecomeASeller/become-seller-mobile.jpg);
    @include sm-up {
      background-image: url(../assets/BecomeASeller/become-seller-small.jpg);
    }
    @include md-up {
      background-image: url(../assets/BecomeASeller/become-seller-medium.jpg);
    }
    @include lg-up {
      background-image: url(../assets/BecomeASeller/become-seller-large.jpg);
    }
  }

  @include sm-up {
    .btn {
      width: auto !important;
    }
  }
  @include md-up {
    .main-container {
      .products {
        .image, .video {
          border-radius: .5rem;
          height: 220px;
        }
        h3 {
          text-align: left;
        }
        .etn {
          .btn {
            width: auto !important;
          }
        }
      }
    }
  }

</style>
